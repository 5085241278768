import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import { getCourseNavigation } from "../../../../../store/courses"
import Poem from "../../../../../components/poem"
import Paragraph from "../../../../../components/paragraph"
import NotationSingle from "../../../../../components/notation-single"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/neologismus" />
      }
    >
      <Seo title="Substantive und Adjektive" />
      <Stack>
        <Heading as="h2" level={2}>
          Substantive und Adjektive
        </Heading>
        <Paragraph>
          Die Substantive der 1. Strophe rufen das Bild einer malerischen
          Landschaft im Spätsommer auf. Fast alle Subjektive werden von
          Adjektiven begleitet, die dieses Bild ausschmücken.
        </Paragraph>
        <Poem size={[3, 3, 4]}>
          <p>
            Mit <NotationSingle type="circle">gelben Birnen</NotationSingle>{" "}
            hänget
          </p>
          <p>
            Und voll mit{" "}
            <NotationSingle type="circle">wilden Rosen</NotationSingle>
          </p>
          <p>Das Land in den See,</p>
          <p>
            Ihr <NotationSingle type="circle">holden Schwäne</NotationSingle>,
          </p>
          <p>
            Und{" "}
            <NotationSingle type="circle">trunken von Küssen</NotationSingle>
          </p>
          <p>Tunkt ihr das Haupt</p>
          <p>
            Ins{" "}
            <NotationSingle type="circle">
              heilignüchterne Wasser
            </NotationSingle>
            .
          </p>
        </Poem>
        <Paragraph>
          Die Substantive der 2. Strophe haben keine Bezugsworte und wirken
          dadurch abstrakter und weniger bildlich. Der Winter wird allein durch
          Absenz charakterisiert: weder Blumen noch Sonnenschein, ja nicht
          einmal Schatten sind hier zu finden. Zurück bleiben lediglich
          sprachlose, kalte Mauern, der Wind und die klirrenden Fahnen.
        </Paragraph>
        <Poem size={[3, 3, 4]}>
          <p>Weh mir, wo nehm’ ich, wenn</p>
          <p>
            Es <NotationSingle>Winter</NotationSingle> ist, die{" "}
            <NotationSingle>Blumen</NotationSingle>, und wo
          </p>
          <p>
            Den <NotationSingle>Sonnenschein</NotationSingle>,
          </p>
          <p>
            Und <NotationSingle>Schatten</NotationSingle> der{" "}
            <NotationSingle>Erde</NotationSingle>?
          </p>
          <p>
            Die <NotationSingle>Mauern</NotationSingle> stehn
          </p>
          <p>
            Sprachlos und kalt, im <NotationSingle>Winde</NotationSingle>
          </p>
          <p>
            Klirren die <NotationSingle>Fahnen</NotationSingle>.
          </p>
        </Poem>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
